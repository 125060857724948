import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../images/logoRnav.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { logout } from '../../actions/user_actions'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, } from 'reactstrap'

export const NavBar = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const [dropdown, setDropdown] = useState(false)
    const [subDropdown, setSubDropDown] = useState(false)
    const [dropdowAux, setDropdownAux] = useState(false)

    const { user, loading, isAuthenticated } = useSelector(state => state.auth)
    const location = useLocation();
    const navigate = useNavigate();

    const handleLinkClick = (sectionId) => {
        navigate(`#${sectionId}`);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            const scrollTarget = sectionElement.offsetTop - 100;
            window.scrollTo(0, scrollTarget);
        }
    };
    const logoutHandler = () => {
        localStorage.removeItem('userToken');
        dispatch(logout());
        setDropdown(false)
        alert.success("logOut")
    }
    const abrirCerrarDropdownAux = () => {
        setDropdownAux(!dropdowAux);
        if (!dropdowAux) {
            setSubDropDown(false);
            setDropdown(false);
        }
    }

    const abrirCerrarDropdown = () => {
        setDropdown(!dropdown);
        if (dropdown) {
            setDropdownAux(false);
        }
    }

    const abrirCerrarSubDropdown = () => {
        setSubDropDown(!subDropdown);
    }

    useEffect(() => {
        const img = new Image();
        img.src = logo;

        setTimeout(() => {
            const sectionId = location.hash.substring(1);

            const sectionsWithScroll = ['about', 'requires', 'contact'];

            const shouldScroll = sectionsWithScroll.includes(sectionId);

            if (shouldScroll && sectionId) {
                scrollToSection(sectionId);
            }
        }, 0);
    }, [location]);
    return (
        <Fragment>
            <nav className="navbar navbar-expand-sm" aria-label="Fourth navbar example">
                <div className="container">
                    <a href="/#inicio" aria-label="Ir a inicio">
                        <div className='navbar-brand'>
                            <img src={logo}
                                alt="Coast Road"
                                className="responsive-Navlogo" />
                        </div>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarToggleExternalContent">
                        <ul className="navbar-nav mx-auto cafe ">
                            <li className="nav-item">
                                <a href="/#inicio" aria-label="Ir a inicio" className="nav-link"><span data-hover="HOME">INICIO</span></a>
                            </li>
                            <li className="nav-item">
                                <a href="/#about" aria-label="Ir a servicios" className="nav-link"><span data-hover="SERVICES">SERVICIOS</span></a>
                            </li>
                            <li className="nav-item">
                                <a href="/#contact" aria-label="Ir a contacto" className="nav-link "><span data-hover="CONTACT">CONTACTO</span></a>
                            </li>
                            <li className="nav-item">
                                <a href="/#testimonials" aria-label="Ir a Testimonios" className="nav-link "><span data-hover="REVIEWS">TESTIMONIOS</span></a>
                            </li>
                            <li className="nav-item">
                                <span>
                                    <NavLink to={"/"} className="nav-link "><i className="bi bi-translate"></i> ENG</NavLink>
                                </span>
                            </li>
                        </ul>

                        {/*Si estoy logueada*/}
                        <Dropdown isOpen={dropdowAux} toggle={abrirCerrarDropdownAux} className='btn-navLoguin'>
                            {isAuthenticated && (
                                <div>
                                    <DropdownToggle className='btn-nav'>
                                        <NavLink>
                                            <figure className='avatar avatar-nav'>
                                                <img src={user.avatar && user.avatar.url} alt={user && user.nombre} className="rounded-circle" loading="lazy" />
                                            </figure>
                                            <span>
                                                {user && user.nombre}
                                            </span>
                                        </NavLink>
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown_menu'>
                                        <div className='navContainer'>
                                            <button className="modal-close" onClick={abrirCerrarDropdownAux}>
                                                <i className="bi bi-x-lg"></i>
                                            </button>
                                            {user && user.role === "admin" && (
                                                <DropdownItem><a href="/admin/dashboard" aria-label="Ir administracion de servicios">Adm. Servicios</a></DropdownItem>
                                            )}
                                            <DropdownItem><a href="/MyProfile" aria-label="Ir a mi perfil">PERFIL</a></DropdownItem>
                                            <DropdownItem><NavLink to={"/"} onClick={logoutHandler} aria-label="Cerrar Sesion" id='logout'>Logout</NavLink></DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </div>
                            )}
                            {!isAuthenticated && !loading &&(
                                <span><NavLink to={"/login"} className=" nav-link text-white" aria-label="Iniciar sesion" id='login_btn'>LOGIN</NavLink></span>
                            )}
                        </Dropdown>

                    </div>


                    <Dropdown isOpen={dropdown} toggle={abrirCerrarDropdown} >
                        <DropdownToggle className='dropdownbutton'>
                            {dropdown ? (<div className='toggle-btn'>
                                <i className="bi bi-x"></i>
                            </div>) : <div className='toggle-btn'>
                                <i className="bi bi-list"></i>
                            </div>}
                        </DropdownToggle>
                        <DropdownMenu className='dropdown_menu' aria-label="Menu desplegable">
                            <div className='navContainer'>
                                <button className="modal-close" onClick={abrirCerrarDropdown}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                                <DropdownItem> <a href="/#inicio" aria-label="Ir a inicio">Inicio</a> </DropdownItem>
                                <DropdownItem> <a href="/#about" aria-label="Ir a servicios" onClick={() => handleLinkClick('about')}>Services</a> </DropdownItem>
                                <DropdownItem> <a href="/#requires" aria-label="Ir a requisitos" onClick={() => handleLinkClick('requires')}>Requires</a> </DropdownItem>
                                <DropdownItem> <a href="/#contact" aria-label="Ir a contacto" onClick={() => handleLinkClick('contact')}>Contact</a></DropdownItem>
                                <DropdownItem> <a href="/#testimonials" aria-label="Ir a testimonios">Testimonios</a> </DropdownItem>
                                <Dropdown isOpen={subDropdown} toggle={abrirCerrarSubDropdown} className='btn-userDrop text-center'>
                                    {isAuthenticated ? (
                                        <Fragment>
                                            <DropdownToggle className='btn-userDrop'>
                                                <NavLink>
                                                    <figure className='avatar avatar-nav'>
                                                        <img
                                                            src={user.avatar && user.avatar.url}
                                                            alt={user && user.nombre}
                                                            className="rounded-circle"></img>
                                                    </figure>
                                                    <span>
                                                        {user && user.nombre}
                                                    </span>
                                                </NavLink>
                                            </DropdownToggle>
                                            <DropdownMenu className='dropdown_menu'>
                                                <div className='navContainer'>
                                                    <button className="modal-close" onClick={abrirCerrarSubDropdown}>
                                                        <i className="bi bi-x-lg"></i>
                                                    </button>
                                                    {user && user.role === "admin" && (
                                                        <DropdownItem><a href="/admin/dashboard" aria-label="Ir a administracion de servicios">Adm. Servicios</a></DropdownItem>
                                                    )}
                                                    <DropdownItem><a href="/MyProfile" aria-label="Ir a mi perfil">Profile</a></DropdownItem>
                                                    <DropdownItem><NavLink to={"/"} onClick={logoutHandler} aria-label="Cerrar sesion" >Log out</NavLink></DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </Fragment>
                                    ) : !loading && <span><NavLink to={"/login"} onClick={abrirCerrarDropdown} className="text-center" aria-label="Login">Login</NavLink></span>}
                                </Dropdown>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </nav>
        </Fragment >
    )
}
