import React, { Fragment, Suspense } from 'react'
import MetaData from './layout/metadata';
import { LoadingPage } from './hooks/LoadingPage';
const About = React.lazy(() => import('./about'));
const Requires = React.lazy(() => import('./requires'));
const Testiomonials = React.lazy(() => import('./reviews/testiomonials'));
const ContactUs = React.lazy(() => import('./contact_us'));

export const Inicio = () => {
    return (
        <Fragment>
            <MetaData title={"CoastRoad"}></MetaData>
            <div>
                <section className='fondoParallax ' id='inicio'>
                    <div className='caption'>
                        <ul>
                            <li>C</li>
                            <li>o</li>
                            <li>a</li>
                            <li>s</li>
                            <li>t</li>
                            <li>~</li>
                            <li>R</li>
                            <li>o</li>
                            <li>a</li>
                            <li>d</li>
                        </ul>
                        <h3>Juntos Al Volante</h3>
                    </div>
                </section>
                <Suspense fallback={<LoadingPage></LoadingPage>}>
                    <section id='about' className='about'>
                        <About></About>
                    </section>
                    <section id='requires' className=''>
                        <Requires></Requires>
                    </section>
                    <section id='contact'>
                        <ContactUs></ContactUs>
                    </section>
                    <section id='testimonials'>
                        <Testiomonials></Testiomonials>
                    </section>
                </Suspense>
                <script src='main.js'></script>

            </div>

        </Fragment>
    )
}
export default Inicio;