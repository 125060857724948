export const NEW_REVIEW_REQUEST="NEW_REVIEW_REQUEST"
export const NEW_REVIEW_SUCCESS="NEW_REVIEW_SUCCESS"
export const NEW_REVIEW_RESET="NEW_REVIEW_RESET"
export const NEW_REVIEW_FAIL="NEW_REVIEW_FAIL"

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

export const GET_BESTREVIEWS_REQUEST = 'GET_BESTREVIEWS_REQUEST'
export const GET_BESTREVIEWS_SUCCESS = 'GET_BESTREVIEWS_SUCCESS'
export const GET_BESTREVIEWS_FAIL = 'GET_BESTREVIEWS_FAIL'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'

export const  CLEAR_ERRORS="CLEAR_ERRORS"

